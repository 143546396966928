<template>
  <v-container fluid fill-height style='height: 90vh' class='pa-0'>
    <v-row no-gutters align='center' justify='center'>
      <v-card flat color='#fff' width='470'>
        <v-card-text v-if='user'>
          <v-row justify='center' class='mt-3'>
            <v-avatar size='100' color='primary lighten-2'>
              <v-icon dark x-large>
                mdi-account
              </v-icon>
            </v-avatar>
          </v-row>
          <v-row justify='center' class='my-5'>
            <span class='text-h5'>{{user.account.name}}</span>
          </v-row>

          <v-form ref='formIsValid' v-model='formIsValid' lazy-validation>
            <v-text-field
              v-model='user.account.email'
              :rules='[rules.required]'
              :label='$t("EMAIL")'
            />
            <v-text-field
              v-model='user.account.cpf'
              :rules='[rules.required]'
              :label='$t("CPF")'
            />
          </v-form>

          <v-btn block color='secondary' class='mt-5' @click='save'>
            {{$t('SAVE')}}
          </v-btn>

          <v-alert
            text
            :icon='false'
            type='warning'
            class='mt-5'
          >
            {{$t('CHANGE_USER_ACCESS_DATA_WARNING')}}
          </v-alert>
        </v-card-text>

        <v-skeleton-loader v-else type='card' />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'UsersAccessData',
    props: {
      accountId: {
        type: Number,
        required: true,
      },
    },
    data: function () {
      return {
        formIsValid: true,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
        },
        user: undefined,
      };
    },
    mounted: function () {
      this.getUserData();
    },
    methods: {
      getUserData: async function () {
        try {
          const { data } = await axios({
            url: '/account-get-by-id',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
            params: {
              accountId: this.accountId,
            },
          });

          this.user = data;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      save: async function () {
        this.formIsValid = this.$refs.formIsValid.validate();
        if (!this.formIsValid) {
          return;
        }

        try {
          const result = await Swal.fire({
            title: this.$t('WARNING'),
            text: this.$t('REALLY_WANT_CHANGE_USER_ACCESS_DATA', { email: this.user.account.email }),
            footer: `<span style='text-align: center;'>${this.$t('WARNING_CHANGE_USER_ACCESS_DATA_TIMEOUT')}</span>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });

          if (!result.value) {
            return;
          }

          await axios({
            url: '/account-update-user-access-data',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              accountId: this.accountId,
              email: this.user.account.email,
              cpf: this.user.account.cpf,
            },
          });

          this.$emit('close');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
